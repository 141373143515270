const getConfig = () => {
  let base = {
    GOOGLE_WEB_CLIENT_ID: "",
    PROTOCOL: "https://",
    // PRODUCTION : 
    // SERVER: "portal-api.cdlpharma.com",
    // TEST : 
    SERVER: "cdms-cdl-testing-api.ablsa.com",
    PORT: "443",
    // DEVELOPMENT :
    // PROTOCOL: "http://",
    // SERVER: "localhost",
    // PORT: "3004",
    API_VERSION: "api/v1",
    HEADERS: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return base;
};

export default getConfig;
